var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [!_vm.isManager ? _c('ResellTicketStatistics', {
    attrs: {
      "report": _vm.statistics
    },
    on: {
      "fetch-report-resell-ticket": _vm.getStatistics
    }
  }) : _vm._e(), _c('ResellTicketListFilters', {
    attrs: {
      "filter": _vm.filterResellTicket,
      "is-manager": _vm.isManager
    },
    on: {
      "fetch": _vm.fetchResellTicket,
      "fetch-clear": _vm.fetchClear,
      "add": _vm.fetchClear
    }
  }), _c('b-card', {
    staticClass: "bg-white",
    attrs: {
      "no-body": ""
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('b-table', {
    ref: "refResellTicket",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "90vh"
    },
    attrs: {
      "bordered": "",
      "hover": "",
      "no-border-collapse": "",
      "sticky-header": "",
      "responsive": "",
      "show-empty": "",
      "primary-key": "id",
      "table-class": "table-resell-ticket",
      "items": _vm.resellTickets,
      "fields": _vm.tableColumns,
      "empty-text": _vm.$t('noRecordFund'),
      "sort-by": _vm.filterResellTicket.sortBy,
      "sort-desc": _vm.filterResellTicket.isSortDirDesc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        return _vm.$set(_vm.filterResellTicket, "sortBy", $event);
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.$set(_vm.filterResellTicket, "sortBy", $event);
      },
      "update:sortDesc": function updateSortDesc($event) {
        return _vm.$set(_vm.filterResellTicket, "isSortDirDesc", $event);
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.$set(_vm.filterResellTicket, "isSortDirDesc", $event);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark text-nowrap text-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("resellTicket.columns.".concat(data.label))) + " ")])];
        }
      };
    }), _vm._l(_vm.tableColumns, function (column) {
      return {
        key: "cell(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {
            key: column.key,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(item[column.key]) + " ")])];
        }
      };
    }), {
      key: "cell(stt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(data.index + 1) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.type ? _c('span', [_vm._v(_vm._s(_vm.$t("resellTicket.type.".concat(item.type))))]) : _vm._e()];
      }
    }, {
      key: "cell(priceTicket)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.priceTicket ? _c('div', [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.priceTicket)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(price)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.price ? _c('div', [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.price)) + " ")]) : _c('div', {
          staticClass: "d-flex-center"
        }, [_c('div', {
          staticClass: "badge badge-info badge-glow p-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.agreement')) + " ")])])];
      }
    }, {
      key: "cell(phone)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', [_c('a', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: "".concat(_vm.$t('resellTicket.btn.call')),
            expression: "`${$t('resellTicket.btn.call')}`",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }],
          staticClass: "hover-underline",
          attrs: {
            "href": 'tel:' + item.phone
          }
        }, [_vm._v(" " + _vm._s(item.phone) + " ")])])];
      }
    }, {
      key: "cell(expiredDate)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.isDateGreaterThanOrEqualToToday(item.expiredDate) ? _vm.convertISODateTime(item.expiredDate).date : _vm.$t('resellTicket.expired')) + " ")])];
      }
    }, {
      key: "cell(note)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "min-width-200 text-break text-justify"
        }, [_vm._v(" " + _vm._s(item.note) + " ")])];
      }
    }, {
      key: "cell(notePrivate)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "min-width-200 text-break text-justify"
        }, [_vm._v(" " + _vm._s(item.notePrivate) + " ")])];
      }
    }, {
      key: "cell(publishDate)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.publishDate).date) + " ")])];
      }
    }, {
      key: "cell(airlineCode)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: "".concat(_vm.getAirline(item === null || item === void 0 ? void 0 : item.airlineCode)),
            expression: "`${getAirline(item?.airlineCode)}`",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }],
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.airlineCode) + " ")])];
      }
    }, {
      key: "cell(paxType)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.paxType ? _c('span', [_vm._v(" " + _vm._s(_vm.$te("reservation.".concat(item.paxType)) ? _vm.$t("reservation.".concat(item.paxType)) : item.paxType) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref12) {
        var _vm$meData, _vm$meData2, _vm$meData3, _vm$meData4;
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "d-flex gap-1 align-items-center"
        }, [_vm.isRoleF1 || [item.agencySellCode].concat((item === null || item === void 0 ? void 0 : item.parentAgencySellCode) && [item === null || item === void 0 ? void 0 : item.parentAgencySellCode]).includes((_vm$meData = _vm.meData) === null || _vm$meData === void 0 ? void 0 : _vm$meData.agency.agencyCode) ? _c('div', {
          staticClass: "text-center"
        }, [item.status !== 'SOLD' ? _c('b-form-checkbox', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-info.window",
            value: "".concat(_vm.$t('resellTicket.columns.isActive')),
            expression: "`${$t('resellTicket.columns.isActive')}`",
            modifiers: {
              "hover": true,
              "v-info": true,
              "window": true
            }
          }],
          attrs: {
            "checked": item.status === 'PUBLIC',
            "name": "check-button",
            "switch": ""
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.confirmDeactive(item);
            }
          }
        }) : _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.status.SOLD')) + " ")])], 1) : _vm._e(), _vm.isRoleF1 || [item.agencySellCode].concat((item === null || item === void 0 ? void 0 : item.parentAgencySellCode) && [item === null || item === void 0 ? void 0 : item.parentAgencySellCode]).includes((_vm$meData2 = _vm.meData) === null || _vm$meData2 === void 0 ? void 0 : _vm$meData2.agency.agencyCode) ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-warning.window",
            value: "".concat(_vm.$t('resellTicket.btn.edit')),
            expression: "`${$t('resellTicket.btn.edit')}`",
            modifiers: {
              "hover": true,
              "v-warning": true,
              "window": true
            }
          }],
          staticClass: "p-50 rounded",
          attrs: {
            "variant": "flat-warning",
            "disabled": item.status === 'SOLD'
          },
          on: {
            "click": function click($event) {
              return _vm.editRT(item);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "editOutline",
            "size": "18"
          }
        })], 1) : [!item.reported ? _c('b-button', {
          staticClass: "p-50 rounded text-nowrap",
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              return _vm.createReport(item.id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.btn.report')) + " ")]) : _vm._e()], item.countReport > 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-danger.window",
            value: "".concat(_vm.$t('resellTicket.btn.reportDetail'), " ( ").concat(item.countReport, " )"),
            expression: "`${$t('resellTicket.btn.reportDetail')} ( ${item.countReport} )`",
            modifiers: {
              "hover": true,
              "v-danger": true,
              "window": true
            }
          }],
          staticClass: "d-flex-center gap-1 py-50 px-75 rounded",
          attrs: {
            "variant": "flat-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.detailReport(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "badge": item.countReport,
            "badge-classes": "bg-danger badge-glow font-small-1",
            "icon": "ThumbsDownIcon",
            "size": "18"
          }
        })], 1) : _vm._e(), _vm.isRoleF1 || [item.agencySellCode].concat((item === null || item === void 0 ? void 0 : item.parentAgencySellCode) && [item === null || item === void 0 ? void 0 : item.parentAgencySellCode]).includes((_vm$meData3 = _vm.meData) === null || _vm$meData3 === void 0 ? void 0 : _vm$meData3.agency.agencyCode) ? _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: "".concat(_vm.$t('resellTicket.btn.logsDetail')),
            expression: "`${$t('resellTicket.btn.logsDetail')}`",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }],
          staticClass: "d-flex-center gap-1 py-50 px-75 rounded",
          attrs: {
            "variant": "flat-info"
          },
          on: {
            "click": function click($event) {
              return _vm.detailLogsHandle(item);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "receipt",
            "size": "18"
          }
        })], 1) : _vm._e(), (_vm.isRoleF1 || [item.agencySellCode].concat((item === null || item === void 0 ? void 0 : item.parentAgencySellCode) && [item === null || item === void 0 ? void 0 : item.parentAgencySellCode]).includes((_vm$meData4 = _vm.meData) === null || _vm$meData4 === void 0 ? void 0 : _vm$meData4.agency.agencyCode)) && item.status !== 'SOLD' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-success.window",
            value: "".concat(_vm.$t('resellTicket.status.SOLD')),
            expression: "`${$t('resellTicket.status.SOLD')}`",
            modifiers: {
              "hover": true,
              "v-success": true,
              "window": true
            }
          }],
          staticClass: "p-50 rounded",
          attrs: {
            "variant": "flat-success"
          },
          on: {
            "click": function click($event) {
              return _vm.confirmSold(item.id);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CheckSquareIcon",
            "size": "18"
          }
        })], 1) : _vm._e()], 2)];
      }
    }], null, true)
  })], 1), _c('b-container', {
    staticClass: "bg-white py-1",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalResellTicket,
      "per-page": _vm.filterResellTicket.size,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterResellTicket.page,
      callback: function callback($$v) {
        _vm.$set(_vm.filterResellTicket, "page", $$v);
      },
      expression: "filterResellTicket.page"
    }
  })], 1), _c('b-col', [_c('v-select', {
    staticClass: "per-page-selector",
    attrs: {
      "options": _vm.sizePerPageLgOptions,
      "clearable": false,
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterResellTicket.size,
      callback: function callback($$v) {
        _vm.$set(_vm.filterResellTicket, "size", $$v);
      },
      expression: "filterResellTicket.size"
    }
  })], 1)], 1)], 1)], 1), _c('UpdateResellTicketModal', {
    attrs: {
      "is-manager": _vm.isManager,
      "resell-ticket": _vm.updateRTReq
    },
    on: {
      "confirm-update": _vm.confirmUpdate
    }
  }), _c('ResellTicketModal', {
    attrs: {
      "is-manager": _vm.isManager
    },
    on: {
      "refresh": _vm.fetchClear
    }
  }), _c('CreateReportResellTicketModal', {
    attrs: {
      "id": _vm.resellTicketId
    },
    on: {
      "refresh": _vm.fetchResellTicket
    }
  }), _c('DetailReportResellTicketModal', {
    attrs: {
      "item": _vm.resellTicket,
      "is-child": _vm.isChild
    },
    on: {
      "refresh": _vm.fetchResellTicket
    }
  }), _c('ResellLogModal', {
    attrs: {
      "resell-ticket": _vm.resellTicket
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }