<template>
  <div class="d-flex flex-column">
    <!-- ANCHOR Reports -->
    <ResellTicketStatistics
      v-if="!isManager"
      :report="statistics"
      @fetch-report-resell-ticket="getStatistics"
    />

    <!-- ANCHOR Filters -->
    <ResellTicketListFilters
      :filter="filterResellTicket"
      :is-manager="isManager"
      @fetch="fetchResellTicket"
      @fetch-clear="fetchClear"
      @add="fetchClear"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="bg-white"
    >
      <!-- SECTION Table content -->
      <IAmOverlay :loading="loading">
        <b-table
          ref="refResellTicket"
          bordered
          hover
          no-border-collapse
          sticky-header
          responsive
          show-empty
          primary-key="id"
          style="max-height: 90vh"
          class="position-relative"
          table-class="table-resell-ticket"
          :items="resellTickets"
          :fields="tableColumns"
          :empty-text="$t('noRecordFund')"
          :sort-by.sync="filterResellTicket.sortBy"
          :sort-desc.sync="filterResellTicket.isSortDirDesc"
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-nowrap text-center"
            >
              {{ $t(`resellTicket.columns.${data.label}`) }}
            </div>
          </template>

          <template
            v-for="column in tableColumns"
            #[`cell(${column.key})`]="{ item }"
          >
            <div
              :key="column.key"
              class="text-nowrap"
            >
              <!-- :class="{ 'text-right': typeof item[column.key] === 'number' }" -->
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR stt. -->
          <template #cell(stt)="data">
            <div class="text-center">
              {{ data.index + 1 }}
            </div>
          </template>

          <template #cell(type)="{ item }">
            <span v-if="item.type">{{ $t(`resellTicket.type.${item.type}`) }}</span>
          </template>

          <!-- ANCHOR priceTicket. -->
          <template #cell(priceTicket)="{ item }">
            <div v-if="item.priceTicket">
              {{ formatVnCurrency(item.priceTicket) }}
            </div>
          </template>

          <!-- ANCHOR price. -->
          <template #cell(price)="{ item }">
            <div v-if="item.price">
              {{ formatVnCurrency(item.price) }}
            </div>
            <div
              v-else
              class="d-flex-center"
            >
              <div class="badge badge-info badge-glow p-50">
                {{ $t('resellTicket.agreement') }}
              </div>
            </div>
          </template>

          <!-- ANCHOR phone. -->
          <template #cell(phone)="{ item }">
            <div>
              <a
                v-b-tooltip.hover.v-dark.window="`${$t('resellTicket.btn.call')}`"
                :href="'tel:' + item.phone"
                class="hover-underline"
              >
                {{ (item.phone) }}
              </a>
            </div>
          </template>

          <!-- ANCHOR expiredDate. -->
          <template #cell(expiredDate)="{ item }">
            <span> {{
              isDateGreaterThanOrEqualToToday(item.expiredDate)
                ? convertISODateTime(item.expiredDate).date
                : $t('resellTicket.expired')
            }}
            </span>
          </template>

          <!-- ANCHOR note. -->
          <template #cell(note)="{ item }">
            <div class="min-width-200 text-break text-justify">
              {{ item.note }}
            </div>
          </template>

          <!-- ANCHOR notePrivate -->
          <template #cell(notePrivate)="{ item }">
            <div class="min-width-200 text-break text-justify">
              {{ item.notePrivate }}
            </div>
          </template>

          <!-- ANCHOR publishDate. -->
          <template #cell(publishDate)="{ item }">
            <span>
              {{ convertISODateTime(item.publishDate).date }}
            </span>
          </template>

          <!-- ANCHOR airlineCode. -->
          <template #cell(airlineCode)="{ item }">
            <span
              v-b-tooltip.hover.v-dark.window="`${getAirline(item?.airlineCode)}`"
              class="text-nowrap"
            >
              {{ item?.airlineCode }}
            </span>
          </template>
          <!-- ANCHOR paxType. -->
          <template #cell(paxType)="{ item }">
            <span v-if="item.paxType">
              {{ $te(`reservation.${item.paxType}`) ? $t(`reservation.${item.paxType}`) : item.paxType }}
            </span>
          </template>

          <!-- ANCHOR actions. -->
          <template #cell(actions)="{ item }">
            <div class="d-flex gap-1 align-items-center">
              <div
                v-if="isRoleF1 || [item.agencySellCode, ...(item?.parentAgencySellCode && [item?.parentAgencySellCode])].includes(meData?.agency.agencyCode)"
                class="text-center"
              >
                <b-form-checkbox
                  v-if="item.status !== 'SOLD'"
                  v-b-tooltip.hover.v-info.window="`${$t('resellTicket.columns.isActive')}`"
                  :checked="item.status === 'PUBLIC'"
                  name="check-button"
                  switch
                  @click.native.prevent="confirmDeactive(item)"
                />
                <b-badge
                  v-else
                  variant="success"
                >
                  {{ $t('resellTicket.status.SOLD') }}
                </b-badge>
              </div>
              <b-button
                v-if="isRoleF1 || [item.agencySellCode, ...(item?.parentAgencySellCode && [item?.parentAgencySellCode])].includes(meData?.agency.agencyCode)"
                v-b-tooltip.hover.v-warning.window="`${$t('resellTicket.btn.edit')}`"
                variant="flat-warning"
                class="p-50 rounded"
                :disabled="item.status === 'SOLD'"
                @click="editRT(item)"
              >
                <IAmIcon
                  icon="editOutline"
                  size="18"
                />
              </b-button>
              <template v-else>
                <b-button
                  v-if="!item.reported"
                  variant="warning"
                  class="p-50 rounded text-nowrap"
                  @click="createReport(item.id)"
                >
                  {{ $t('resellTicket.btn.report') }}
                </b-button>
              </template>
              <b-button
                v-if="item.countReport > 0"
                v-b-tooltip.hover.v-danger.window="`${$t('resellTicket.btn.reportDetail')} ( ${item.countReport} )`"
                variant="flat-danger"
                class="d-flex-center gap-1 py-50 px-75 rounded"
                @click="detailReport(item)"
              >
                <feather-icon
                  :badge="item.countReport"
                  :badge-classes="`bg-danger badge-glow font-small-1`"
                  icon="ThumbsDownIcon"
                  size="18"
                />
              </b-button>
              <BButton
                v-if="isRoleF1 || [item.agencySellCode, ...(item?.parentAgencySellCode && [item?.parentAgencySellCode])].includes(meData?.agency.agencyCode)"
                v-b-tooltip.hover.v-dark.window="`${$t('resellTicket.btn.logsDetail')}`"
                variant="flat-info"
                class="d-flex-center gap-1 py-50 px-75 rounded"
                @click="detailLogsHandle(item)"
              >
                <IAmIcon
                  icon="receipt"
                  size="18"
                />
              </BButton>

              <b-button
                v-if="(isRoleF1 || [item.agencySellCode, ...(item?.parentAgencySellCode && [item?.parentAgencySellCode])].includes(meData?.agency.agencyCode)) && item.status !== 'SOLD'"
                v-b-tooltip.hover.v-success.window="`${$t('resellTicket.status.SOLD')}`"
                variant="flat-success"
                class="p-50 rounded"
                @click="confirmSold(item.id)"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  size="18"
                />
              </b-button>
            </div>
          </template>
        </b-table>
      </IAmOverlay>
      <!-- !SECTION -->

      <!-- SECTION: Table Footer -->
      <b-container
        fluid
        class="bg-white py-1"
      >
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="filterResellTicket.page"
              :total-rows="totalResellTicket"
              :per-page="filterResellTicket.size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col>
            <v-select
              v-model="filterResellTicket.size"
              :options="sizePerPageLgOptions"
              :clearable="false"
              append-to-body
              :calculate-position="withPopper"
              class="per-page-selector"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-col>
        </b-row>
      </b-container>
      <!-- SECTION paging -->
    </b-card>

    <!-- embed modal -->
    <UpdateResellTicketModal
      :is-manager="isManager"
      :resell-ticket="updateRTReq"
      @confirm-update="confirmUpdate"
    />
    <ResellTicketModal
      :is-manager="isManager"
      @refresh="fetchClear"
    />
    <CreateReportResellTicketModal
      :id="resellTicketId"
      @refresh="fetchResellTicket"
    />
    <DetailReportResellTicketModal
      :item="resellTicket"
      :is-child="isChild"
      @refresh="fetchResellTicket"
    />
    <ResellLogModal :resell-ticket="resellTicket" />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormCheckbox,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import {
  computed, ref, onMounted,
} from '@vue/composition-api'
import { assign, keys, pick } from 'lodash'
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

import { sizePerPageLgOptions } from '@/constants/selectOptions'
import store from '@/store'

import {
  convertISODateTime,
  isDateGreaterThanOrEqualToToday,
  formatVnCurrency,
} from '@core/utils/filter'

import useResellTicketHandle from '@saleReport/useResellTicketHandle'

export default {
  components: {
    BContainer,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BPagination,
    BButton,
    BBadge,
    vSelect,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    UpdateResellTicketModal: () => import('@saleReport/resell-ticket/UpdateResellTicketModal.vue'),
    ResellTicketModal: () => import('@saleReport/resell-ticket/ResellTicketModal.vue'),
    CreateReportResellTicketModal: () => import('@saleReport/resell-ticket/CreateReportResellTicketModal.vue'),
    ResellTicketListFilters: () => import('@saleReport/resell-ticket/ResellTicketListFilters.vue'),
    ResellTicketStatistics: () => import('@saleReport/resell-ticket/ResellTicketStatistics.vue'),
    DetailReportResellTicketModal: () => import('@saleReport/resell-ticket/DetailReportResellTicketModal.vue'),
    ResellLogModal: () => import('@saleReport/resell-ticket/ResellLogModal.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const {
      loading,
      isManager,
      dataMeta,
      tableColumns,
      refResellTicket,
      updateRTReq,
      totalResellTicket,
      resellTickets,
      filterResellTicket,
      fetchClear,
      fetchResellTicket,
      updateResellTicket,
      deleteResellTicket,
      deactiveResellTicket,
      getStatistics,
      statistics,
      onMountedFunction,
      airlineSupports,
      sold,
    } = useResellTicketHandle()
    onMountedFunction()
    const resellTicketId = ref(null)
    const resellTicket = ref(null)
    const isChild = ref(false)

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const meData = computed(() => store.getters['userStore/getMeData'])

    function editRT(item) {
      assign(updateRTReq.value, pick(item, keys(updateRTReq.value)))
      this.$bvModal.show('update-resell-ticket-modal')
    }

    async function confirmUpdate(id, item) {
      updateRTReq.value = item
      this.$bvModal.show('modal-api-loading')
      await updateResellTicket(id)
      this.$bvModal.hide('modal-api-loading')
    }

    function confirmDelete(id) {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmDelete') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirmDelete'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            deleteResellTicket(id)
          }
        })
    }

    function confirmDeactive(item) {
      if (item.status === 'SOLD') return
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmDeactive') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirmDeactive'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            await deactiveResellTicket(item.id)
            this.$bvModal.hide('modal-api-loading')
          }
        })
    }

    function confirmSold(id) {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.sold') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'warning',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            sold(id).then(() => {
              fetchResellTicket()
            })
          }
        })
    }

    function createReport(id) {
      resellTicketId.value = id
      this.$bvModal.show('report-resell-ticket-modal')
    }

    function detailReport(item) {
      if (!isRoleF1.value) return
      resellTicket.value = item
      isChild.value = isRoleF1.value || [item?.agencySellCode, ...(item?.parentAgencySellCode ? [item?.parentAgencySellCode] : [])].includes(meData.value?.agency.agencyCode)
      this.$bvModal.show('detail-report-resell-ticket-modal')
    }

    function getAirline(code) {
      if (!code) return ''
      const rs = airlineSupports.value.find(e => e.code === code)
      if (!rs) return ''
      return `${rs?.name} (${rs?.code})`
    }

    function detailLogsHandle(item) {
      resellTicket.value = item
      this.$bvModal.show('modal-resell-logs')
    }

    onMounted(() => {
      fetchResellTicket()
    })
    return {
      loading,
      isRoleF1,
      tableColumns,
      resellTicketId,
      resellTicket,
      filterResellTicket,
      updateRTReq,
      statistics,
      meData,
      resellTickets,
      refResellTicket,
      dataMeta,
      totalResellTicket,
      isManager,
      convertISODateTime,
      isDateGreaterThanOrEqualToToday,
      sizePerPageLgOptions,
      formatVnCurrency,
      fetchResellTicket,
      fetchClear,
      confirmDelete,
      confirmDeactive,
      confirmUpdate,
      confirmSold,
      editRT,
      getStatistics,
      createReport,
      detailReport,
      isChild,
      getAirline,
      detailLogsHandle,
    }
  },
  watch: {
    $route(to) {
      this.isManager = to.name.includes('manager')
      this.fetchResellTicket()
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.maxWidth = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
